/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/types/binaural/'], ['en', 'https://www.hear.com/hearing-aids/types/binaural/'], ['en-US', 'https://www.hear.com/hearing-aids/types/binaural/'], ['en-CA', 'https://ca.hear.com/hearing-aids/types/binaural/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "improved-hearing-at-last",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#improved-hearing-at-last",
    "aria-label": "improved hearing at last permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Improved hearing at last!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids are technologically advanced assistive devices that are on the same level as modern computers. Those old-looking devices with outdated technology have come and gone. Today’s hearing aids are far more than simple audio amplifiers; they now come loaded with a number of special features that enhance performance and usability."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Below, you can read about what binaural hearing aids offer and how they can make your listening experience much more pleasant. Of course, our hearing aid experts are available to help you with any questions related to hearing loss – free of charge and without obligation."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "binaural-hearing-aids-make-hearing-even-more-natural",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#binaural-hearing-aids-make-hearing-even-more-natural",
    "aria-label": "binaural hearing aids make hearing even more natural permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Binaural hearing aids make hearing even more natural"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Outdated hearing aid models – individually and independent of each other – amplify sounds. However, modern binaural hearing systems, similar to natural hearing, couple the left and right hearing aids together via a radio signal. The devices are able to intelligently communicate and coordinate with each other.\nDue to the signals from both ears, the auditory center in the brain manages to adequately filter speech from sound and suppress background noises. In this way, speech intelligibility is also significantly improved, even in acoustically demanding situations. Do you feel uncomfortable when you are at a restaurant, in a full train, or in crowded and noisy public places, because you feel you are unable to understand the people with whom you are speaking? We can change that with the help of a binaural hearing system."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-independent-of-your-line-of-sight",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-independent-of-your-line-of-sight",
    "aria-label": "hearing independent of your line of sight permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing independent of your line of sight"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The direction of the microphone normally corresponds with the hearing aid wearer’s line of sight. But if you are sitting at your personal computer and concentrating on the screen, and still want to converse with a person behind you, the older hearing systems will not be of much use. Modern binaural hearing aids, on the other hand, are able to rotate the microphones in the direction from which the speech signal originates, allowing you to have conversations without having to make eye contact."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "localization",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#localization",
    "aria-label": "localization permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Localization"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Orientation in a room and problem-free directional hearing essentially requires two healthy ears. The auditory center recognizes the tiniest differences between the incoming signals in the left and right ear, and allocates incoming signals to a position in the room (head shadow effect). Binaural signal processing also makes this possible with hearing aids, therefore enabling you to adequately assess direction."), "\n", React.createElement(ButtonCta, {
    copy: "START YOUR RISK-FREE TRIAL",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
